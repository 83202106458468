'use client';

import Image from 'next/future/image';
import React, { useEffect, useLayoutEffect } from 'react'
import _ from 'lodash';
import { Swiper, SwiperSlide } from "swiper/react";

import DefaultLayout from '@/layouts/default'
import { Pagination,EffectFade } from "swiper";
import { triggerSegmentEvent } from '@/lib/segment';
import IconChevronRight from '@/components/svg/icon-chevron-right';
import IconCheck from '@/components/svg/icon-check';

const Homepage = () => {
  const [tabOpen, setTabOpen] = React.useState([]);
  const [queryStr, setQueryStr] = React.useState('')
  const [swiper,setSwiperInstance] = React.useState(null)

  useLayoutEffect(() => {
    setQueryStr(window ? window.location?.search : '')
  }, [])

  const handleClick  = function(val) {
    var new_arr = tabOpen;
    var idx = _.indexOf(tabOpen, val)
    if (idx<0){
      new_arr.push(val)
    }else{
      new_arr = _.without(new_arr,val)
    }
    
    new_arr = _.uniq(new_arr)
    setTabOpen(new_arr)
  }
  
  const checkOpen = function (val){
    var in_array = _.indexOf(tabOpen,val)
    return in_array>=0 ? true : false;
  }

  const featureSwipe = function(val){
    console.log(swiper,'swiper')
   if(swiper==null) return false;
    if(val==1){
      swiper.slideNext()
    }else{
      swiper.slidePrev()
    }
  }

  useEffect(() => {
    triggerSegmentEvent({ event: 'TR Landing Page Pina Trade Viewed'});
  }, []);

  return (
    <main className="transition-all bg-[#F3F4F4] text-[#032425] ">
      
      <div id="top-area" className="overflow-hidden bg-contain md:bg-[100%_30%] md:bg-[length:50%_auto] lg:bg-[length:auto_100%] bg-no-repeat bg-right-bottom  ">
      <div className="container w-full md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-10/12 mx-auto md:px-4 relative gap-4">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:mb-15">
           
          <div className="w-full px-6 md:px-0   pt-8 pb-4 mt-10 md:mt-0 md:pt-14 lg:pt-16 col-span-12 item-center text-center ">
              
              <p className="hidden md:block">&nbsp;</p>
              <p>&nbsp;</p>
              <h1 className="md:text-6xl leading-tight font-semibold py-12 bg-clip-text text-transparent bg-gradient-to-r to-[#032425] from-[#595120]">Stock Trading & Investing App</h1>
            <p className='text-2xl'>Trading saham bisa di <strong className='text-[#595120]'>Antrian terdepan</strong>, Order Masuk Lebih Cepat, Fee Transaksi Bersaing
            {/* dan<br/> <strong>Trading Fee 0,1%*</strong> */}
            </p>
            <div className=" mt-12">
              <a onClick={() => triggerSegmentEvent({ event: 'TR Register Button Web', properties: { source: 'Hero CTA'}})} href={`https://app.pinatrade.id/register${queryStr}`} className="button border-2  px-8 py-3 border-[#595120] rounded-lg hover:bg-[#595120] hover:text-[#f1ff58] cursor-pointer transition ease-in-out delay-150 hover:-translate-y-0.5 hover:scale-105 duration-300">
                <span className=" text-md font-semibold">Mulai Trading Sekarang!</span>
              </a>
            </div>
            <p className='w-full mt-20 text-xs'>Transaksi dari mana saja kapan aja di versi mobile yang lebih <b>ringan</b> dan <b>praktis</b></p>
            <div className="flex mt-4 w-full item-center justify-center">
              
              <a className='flex mr-3' href='https://play.google.com/store/apps/details?id=app.pina.trade&hl=in' >
              <Image
                src="/assets/images/homepage/gplay-download.png"
                width={100}
                height={75}
              />
              </a>
              <a className='flex mr-3' href='https://apps.apple.com/id/app/pina-trade/id6471950472'>
              <Image
                src="/assets/images/homepage/app-store-download.png"
                width={100}
                height={75}
              />
              </a>
              </div>
               
              <p className="hidden md:block">&nbsp;</p>
            <p>&nbsp;</p>
          </div>

          

        </div>
      </div>
      </div>
     
      <div className="container px-6 w-full md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-10/12 mx-auto md:px-4 relative gap-4 mb-20 mt-3">
        <div className="grid gap-8 grid-cols-1 md:grid-cols-3">
            <div className="bg-clip bg-transparent bg-gradient-to-br to-[#928849] from-[#216858] box-shadow px-6 py-8 rounded-sm text-center flex flex-col transition ease-in-out delay-150  duration-300">
              <div className="flex  justify-center mb-6">
                <Image
                    src="/assets/images/fast.png"
                    width={72}
                    height={72}
                    
                  />
              </div>
              <div>
                <h2 className="text-2xl font-bold text-[#E5EAE6] mb-4">Transaksi Cepat</h2>
                <div className='justify-left flex mt-3'>
                  <div className="mx-2 flex items-center  justify-center  w-6 h-6 rounded-lg overflow-hidden"> 
                  <IconCheck color='transparent' colorText='#E5EAE6' className="h-3 w-3" /> </div>
                  <p className='text-md text-[#E5EAE6] text-left'>Masuk orderlist cepat dan mudah <br className="hidden md:flex"/>dengan Fast Order</p>
                  
                </div>
                <div className='justify-left flex'>
                  <div className="mx-2 flex items-center  justify-center text-[#928849] w-6 h-6 rounded-lg overflow-hidden"> 
                  <IconCheck color='transparent' colorText='#E5EAE6' className="h-3 w-3" /> </div>
                  <p className='text-md text-[#E5EAE6] text-left'>Satu halaman bisa banyak toolsnya</p>
                  
                </div>
                <div className='justify-left flex '>
                  <div className="mx-2 flex items-center  justify-center text-[#928849] w-6 h-6 rounded-lg overflow-hidden"> 
                  <IconCheck color='transparent' colorText='#E5EAE6' className="h-3 w-3" /> </div>
                  <p className='text-md text-[#E5EAE6] text-left'>Auto split order biar gak pegel</p>
                  
                </div>
               
              </div>
            </div>
            
            <div className="bg-[#f1ff58] box-shadow px-8 py-8 rounded-sm text-center flex flex-col transition ease-in-out delay-150 hover:-translate-y-0.2 hover:scale-102 duration-300">
             
              <div>
              <p className='text-sm text-black'>Komisi Trading </p>
                <h2 className="font-bold mb-2 text-[5rem] text-black ">0,1%</h2>
                <p className='text-md text-black mb-2'>Tanpa minimum deposit, bisa langsung trading kapan saja!</p>
                <a onClick={() => triggerSegmentEvent({ event: 'TR Register Button Web', properties: { source: 'Hero CTA'}})} href={`https://app.pinatrade.id/register${queryStr}`} className=" grid  mt-4 text-center button bg-[#595120] px-2 py-3 rounded-3xl hover:bg-[#928849] cursor-pointer transition justify-center flex">
                <div className="text-white text-md font-semibold justify-between flex ">Mulai Trading Sekarang
                <div className="mx-2 flex items-center bg-[#f1ff58] justify-center text-[#928849] w-6 h-6 rounded-lg overflow-hidden"> 
            <IconChevronRight className="h-3 w-3" />
          </div>
                </div>
              </a>
                

                <p className='text-2xs text-black mt-4'><sup >*</sup>Syarat & ketentuan berlaku</p>
              </div>
            </div>

            <div className="bg-clip bg-transparent bg-gradient-to-br to-[#928849] from-[#216858] rounded-sm box-shadow px-4 py-8 text-center flex flex-col transition ease-in-out delay-150 duration-300">
              <div className="flex  justify-center mb-6">
                <Image
                    src="/assets/images/win.png"
                    width={72}
                    height={72}
                  />
              </div>
              <div>
                <h2 className="text-2xl text-[#E5EAE6] mb-4 font-bold">Antri Jalur Cepat</h2>
                <div className='justify-left flex mt-3'>
                  <div className="mx-2 flex items-center  justify-center text-[#928849] w-6 h-6 rounded-lg overflow-hidden"> 
                  <IconCheck color='transparent' colorText='#E5EAE6' className="h-3 w-3" /> </div>
                  <p  className=' text-md text-[#E5EAE6] text-left '>Antrian diurutan terdepan</p>
                </div>
                <div className='justify-left flex '>
                  <div className="mx-2 flex items-center  justify-center text-[#928849] w-6 h-6 rounded-lg overflow-hidden"> 
                  <IconCheck color='transparent' colorText='#E5EAE6' className="h-3 w-3" /> </div>
                  <p  className='text-md text-[#E5EAE6] text-left '>Basket Order dari hari sebelumnya </p>
                </div>
                <div className='justify-left flex '>
                  <div className="mx-2 flex items-center  justify-center text-[#928849] w-6 h-6 rounded-lg overflow-hidden"> 
                  <IconCheck color='transparent' colorText='#E5EAE6' className="h-3 w-3" /> </div>
                  <p  className='text-md text-[#E5EAE6] text-left '>Match lebih cepat</p>
                </div>
                
                
              </div>
            </div>
        </div>
      </div>
      
      

    
      <div className='py-5 md:py-10 bg-white'>
      <div className="container px-6 w-full md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-10/12 mx-auto md:px-4 relative gap-4 mb-10 text-center">
        <h1 className="text-2xl lg:text-h1 text-black font-bold mb-6 mt-12">Tools analisa saham lengkap, dan flexible</h1>
        <p className='text-xl'>Semua aktivitas trading kamu dapat dilakukan dalam 1 platform saja!</p>
        <div className="w-full min-h-[250px] col-span-12 md:col-span-7 item-center text-center" >
            <div className=" md:w-[1000px] md:my-10 mx-4 md:mx-0 my-3 mx-auto">
              <Image
                src="/assets/images/homepage/top_image.jpg?2"
                alt=""
                quality={90}
                width={1000}
                height={700}
                priority
              />
            </div>

          </div>
      </div>

      
      <div className='container mx-auto block md:py-5 px-5'>
        <div className="w-full  md:flex gap-4 md:justify-between h-full item-center">
        <div className="mt-6  min-w[50%] item-center">
          <Image
                src="/assets/images/homepage/workspace.gif"
                alt=""
                className="w-full"
                width={1105}
                height={900}
                unoptimized={true}
              />
              </div>
        <div className="mt-6 h-full text-left md:max-w-[50%] item-center self-center p-5"><h3 className=' text-4xl bg-clip-text text-transparent bg-gradient-to-r from-[#928849] to-[#315210] font-semibold mb-4'>Dibuat untuk Trader <br></br>seperti kamu</h3>
        <p className='md:font-semibold'>
        Di PINA Trade, kamu bisa memiliki dashbord personal, 
        yang bisa kamu atur sesuai dengan gaya trading kamu. 
        Bertransaksi dengan nyaman, cepat, dan aman 
        dengan puluhan custom widget pilihan. 
        Plus, dapatkan fitur dan informasi yang kamu butuhkan
        untuk membuat keputusan investasi yang cerdas.
        </p>
        </div>
        </div>
      </div>
      </div>

<div className='bg-[#DEDBCC] py-10 px-5'>
      <div className=' container mx-auto '>
      <div className="container mx-auto mt-6 text-left  item-center self-center py-4"><h3 className=' text-4xl bg-clip-text text-transparent bg-gradient-to-r from-[#928849] to-[#315210] font-semibold mb-4'>Optimalkan <br></br>Strategi Investasimu</h3></div>
      <div className="w-full mx-auto relative gap-4">


      <div className="w-full h-full gap-8 grid md:grid-cols-2 mb-20 py-5">

        <div className="flex min-h-full justify-between w-full md:min-w-[30%] mb-3  bg-white rounded-md overflow-hidden  box-shadow transition ease-in-out delay-150 ">

              <div className=" w-[50%] md:h-[300px] h-full bg-white item-center p-3 self-end">
                <Image
                  src="/assets/images/homepage/compare.gif?1"
                  alt=""
                  className="w-auto h-auto self-baseline"
                  width={1105}
                  height={500}
                  unoptimized={true}
                />

              </div>

              <div className="min-h-full w-[50%] z-10  px-5 py-8 block bg-clip bg-transparent bg-gradient-to-br  md:shadow-none">
               
                  <h1 className="text-black text-xl  bg-clip-text text-transparent bg-gradient-to-r from-[#928849] to-[#315210] md:mb-6 mb-2 font-bold">Bandingkan tiap Aset Investasi</h1>
                                    <p className="relative text-md text-stone-600">Lihat data fundamental dan teknikal analisis 
                                    saham pilihanmu, dan bandingkan detail saham secara realtime.
                      </p>
              
                
              </div>

          </div>

          <div className="flex flex min-h-full justify-between md:min-w-[30%]  mb-3  bg-white rounded-md overflow-hidden  box-shadow  transition ease-in-out delay-150 hover:-translate-y-0.5 hover:scale-105 duration-300">

              <div className=" w-[50%] bg-white item-center p-3  h-[100%] item-end">
                <Image
                  src="/assets/images/homepage/tradeplanner.png?1"
                  alt=""
                  className="w-auto h-auto self-baseline"
                  width={1105}
                  height={500}
                  priority
                />

              </div>

              <div className="min-h-full w-[50%] z-10  px-5 py-8 block bg-clip bg-transparent bg-gradient-to-r   md:shadow-none">
               
                  <h1 className="text-black text-xl  bg-clip-text text-transparent bg-gradient-to-r from-[#928849] to-[#315210] md:mb-6 mb-2 font-bold">Investasi terencana
                      di Trading Planner</h1>
                                    <p className="relative text-md text-stone-600">Lebih tenang saat berinvestasi, atur portofoliomu
                      secara otomatis dengan Smart Buy, Smart Sell, 
                      Stop loss, Take Profit, sesuai target harga 
                      yang kamu tentukan. 
                      </p>
              
                
              </div>
          </div>

          <div className="flex flex min-h-full justify-between md:min-w-[30%]  mb-3  bg-white rounded-md overflow-hidden  box-shadow transition ease-in-out delay-150 hover:-translate-y-0.5 hover:scale-105 duration-300 ">

              <div className=" w-[50%] md:h-[300px] bg-white item-center p-3  h-[100%] grow self-strecth">
                <Image
                  src="/assets/images/homepage/fastorder.png?1"
                  alt=""
                  className="w-auto h-auto"
                  width={1105}
                  height={500}
                  priority
                />

              </div>

              <div className="min-h-full w-[50%] z-10  px-5 py-8 block bg-clip bg-transparent bg-gradient-to-r   md:shadow-none">
               
              <h1 className="text-black text-xl bg-clip-text text-transparent bg-gradient-to-r from-[#928849] to-[#315210] md:mb-6 mb-2 font-bold">Fast Order</h1>
                <p className="relative text-stone-600 text-md">Jual-beli saham secepat kilat, cukup dengan masukan jumlah lot di harga terbaik</p>
              
                
              </div>
          </div>


          <div className="flex flex min-h-full justify-between md:min-w-[30%]  mb-3  bg-white rounded-md overflow-hidden  box-shadow transition ease-in-out delay-150 hover:-translate-y-0.5 hover:scale-105 duration-300 ">

              

          <div className="min-h-full w-[100%] z-10  px-5 py-8 block bg-clip bg-transparent bg-gradient-to-r bg-[#E5EAE6]  md:shadow-none">
          <span className='bg-[#C15120] w-[120px] p-1 px-2 rounded-xs text-xs text-white  '>Coming Soon</span>
              <h1 className="text-black mt-2 text-xl bg-clip-text text-transparent bg-gradient-to-r from-[#928849] to-[#315210] md:mb-6 mb-2 font-bold">Technical Stock Screener, 
Trading Strategy, 
& Backtesting</h1>
                <p className="relative text-stone-600 text-md">Stock screener untuk trader, 
                  back testing performa 
                  trading strategy kamu yang paling optimal. 
                  eksekusi langsung dengan mudah. grati</p>
              
                
              </div>
          </div>

          <div className="flex flex min-h-full justify-between md:min-w-[30%]  mb-3  bg-white rounded-md overflow-hidden  box-shadow  transition ease-in-out delay-150  duration-300">

              <div className="min-h-full w-[100%] z-10  px-5 py-8 block bg-clip bg-transparent bg-gradient-to-r  md:shadow-none">
              <span className='bg-[#C15120] w-[120px] p-1 px-2 rounded-xs text-xs text-white  '>Coming Soon</span>
              <h1 className="text-black mt-2 text-xl bg-clip-text text-transparent bg-gradient-to-r from-[#928849] to-[#315210] md:mb-6 mb-2 font-bold">Sinyal Bandarmology</h1>
                <p className="relative text-stone-600 text-md">Sinyal analisa pergerakan kumulasi distribusi saham, 
seasonal trend, rotational chart,  market maker analisis, 
dan lebih banyak lagi.
ada kelasnya juga buat yang mau belajar. gratis.</p>
              
                
              </div>
          </div>
          <div className="flex flex min-h-full justify-between md:min-w-[30%]  mb-3  bg-white rounded-md overflow-hidden  box-shadow  transition ease-in-out delay-150  duration-300">

            <div className="min-h-full w-[100%] z-10  px-5 py-8 block   md:shadow-none">
            <span className='bg-[#C15120] w-[120px] p-1 px-2 rounded-xs text-xs text-white  '>Coming Soon</span>
            <h1 className="text-black mt-2 text-xl bg-clip-text text-transparent bg-gradient-to-r from-[#928849] to-[#315210] md:mb-6 mb-2 font-bold">Single Stock Futures</h1>
              <p className="relative text-stone-600 text-md">
              Ingin berinvestasi saham tanpa perlu modal besar? Coba Single Stock Futures (SSF), di mana Anda hanya perlu membayar 4%-20% dari total nilai transaksi! Mulai investasi dengan mudah sekarang!
              </p>
              <div className="relative text-[#315210] text-md mt-3 flex"><span className='block icon-alert icon h-4 w-4  bg-[#C15120] rounded-xs mr-2 self-center'></span>Sosialisasi SSF Pina Trade bersama IDX,  2 Maret 2024</div>
                  <a href='http://bit.ly/webinar-kp-idx' rel="noreferrer" target='_blank' className="relative text-[#C15120] hover:text-[#928849] text-sm mt-1 ml-5">Join Webinar</a>
                    
                  </div>
          </div>
        
        
        </div>
      </div>
      </div>
    </div>
      {/*
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-8 my-8 relative">

          <div className="md:w-[60%] w-[100%] col-span-1 md:col-span-12 mx-auto">
              <Swiper 
              slidesPerView={1}
              spaceBetween={40}
              pagination={false}
              effect={'fade'}
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              loop={true}
              className="md:-mr-8"
              modules={[EffectFade,Pagination]}>
               <SwiperSlide>
                <div className="flex justify-center bg-white w-full relative rounded-md overflow-hidden">
                  <Image
                    src="/assets/images/technical_indicator.jpg?2"
                    alt=""
                    className="w-full ml-20"
                    width={1400}
                    height={900}
                    priority
                  />
                  <p className="left-0 text-right hidden md:block absolute bottom-8 w-[150px] text-sm z-10">Technical Indicator</p>
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="flex justify-center bg-white w-full md:min-h-[400px] relative rounded-md overflow-hidden">
                <Image
                  src="/assets/images/advance_chart.jpg?2"
                  alt=""
                  className="w-full ml-20"
                  width={1400}
                  height={900}
                  priority
                />
                <p className="left-0 text-right hidden md:block absolute bottom-8 w-[150px] text-sm z-10">Advance Chart</p>
                </div>
                </SwiperSlide>
              </Swiper>
         

          </div>

          <div className="md:order-first md:absolute md:pt-0 pb-4 mb:pt-14 col-span-1 h-full md:col-span-1 flex items-center px-6 md:px-0 z-10">
            <div className="md:absolute md:w-[400px] -mt-[80px] md:mt-0">
              <div className='bg-clip bg-transparent bg-gradient-to-r from-[#F0F2F3] to-[#DDD184] px-10 py-8 block w-auto rounded-xs box-shadow md:shadow-none h-full'>
                <h1 className="text-black text-2xl lg:text-h2 bg-clip-text text-transparent bg-gradient-to-r from-[#928849] to-[#315210] md:mb-6 mb-2 font-bold">Tools Analisa lengkap</h1>
                <p className="relative text-md text-stone-600">Mulai dari Advance chart real <br className="hidden md:block" />time, Stock screener hingga  <br className="hidden md:block" />Technical indicator yang  <br className="hidden md:block" />lengkap, semua hadir didalam <br className="hidden md:block" />1 trading platform</p>
            
              </div>

              <div className="flex mt-4">
                <a className="cursor-pointer" onClick={()=>featureSwipe(-1)}>
                <Image
                    src="/assets/icon/arrow-left.svg"
                    alt=""
                    width={32}
                    height={32}
                    priority
                  />
                </a>
                <a className="cursor-pointer" onClick={()=>featureSwipe(1)}>
                <Image
                    src="/assets/icon/arrow-right.svg"
                    alt=""
                    width={32}
                    height={32}
                    priority
                  />
                </a>
              </div>
            </div>
          </div>

        </div>

        
      
    </div> */}

      <div className="w-full mx-auto relative gap-4 bg-[#242313]">
        <div className="container px-6 w-full md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-10/12 mx-auto md:px-4 relative gap-4 py-16">
          <h1 className="text-2xl lg:text-h1 text-white font-bold mb-20 text-center">Trust Sekuritas</h1>
          
          <div className="md:flex md:justify-between">
            <div className='button-diamond px-4 py-4 text-white inline-block rounded-lg h-[115px] md:h-auto md:rounded-[60px] lg:rounded-[80px] overflow-hidden mb-2 border-2 border-[#928849]-700 mr-4 w-[140px] md:w-[200px] lg:w-[280px] md:text-center' style={{backgroundImage: "url(./assets/images/bg_diamond.jpg)"}}>
              <span className='font-bold text-[36px] leading-[36px] lg:text-[48px] md:leading-[48px] mr-2 lg:mr-4'>40</span>
              <span className="text-white inline-block text-left text-[13px] md:text-md lg:text-[18px] leading-[1.3rem] md:leading-[10px] lg:leading-[12px]">Years of <br className='hidden md:block'/>Work Experience</span>
            </div>
            <div className='button-diamond px-4 py-4 text-white inline-block rounded-lg h-[115px] md:h-auto md:rounded-[60px] lg:rounded-[80px] overflow-hidden mb-2 border-2 border-[#928849]-700 mr-4 w-[140px] md:w-[200px] lg:w-[280px] md:text-center' style={{backgroundImage: "url(./assets/images/bg_diamond.jpg)"}}>
              <span className='font-bold text-[36px] leading-[36px] lg:text-[48px] md:leading-[48px] mr-2 lg:mr-4'>1T</span>
              <span className="text-white inline-block text-left text-[13px] md:text-md lg:text-[18px] leading-[1.3rem] md:leading-[10px] lg:leading-[12px]">Monthly <br className='hidden md:block'/>Transaction</span>
            </div>
            <div className='button-diamond px-4 py-4 text-white inline-block rounded-lg h-[115px] md:h-auto md:rounded-[60px] lg:rounded-[80px] overflow-hidden mb-2 border-2 border-[#928849]-700 mr-4 w-[140px] md:w-[200px] lg:w-[280px] md:text-center' style={{backgroundImage: "url(./assets/images/bg_diamond.jpg)"}}>
              <span className='font-bold text-[36px] leading-[36px] lg:text-[48px] md:leading-[48px] mr-2 lg:mr-4'>10k+</span>
              <span className="text-white inline-block text-left text-[13px] md:text-md lg:text-[18px] leading-[1.3rem] md:leading-[10px] lg:leading-[12px]">Satisfied <br className='hidden md:block'/>clients</span>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-12">
                <div className="col-span-1 md:col-span-8 lg:col-span-6  text-white-100">
                  <div className='grid col-span-6 md:pr-20'>
                    <p className="font-bold text-white">Nikmati layanan trading saham dari PT Trust Sekuritas, Perusahaan Efek berpengalaman yang sudah terdaftar dan diawasi oleh Otoritas Jasa Keuangan (OJK).</p>
                    <hr className='border-[#928849]-600 w-10 pb-6 mt-6'/>
                    <p className="text-[#D2D2CF]">
                    PT Trust Sekuritas berdiri sejak tahun 1981 dengan nama PT Buraksa Perkasa dan sejak tahun 2001 menjadi PT Trust Sekuritas yang sudah aktif terlibat di pasar modal Indonesia sebagai Perusahaan Efek.
                    <br/><br/>
                    PT Trust Sekuritas melalui PINA Trade membuka pintu akses terhadap investasi selebar – lebarnya untuk semua kalangan, baik itu investor pemula maupun investor berpengalaman.
                    </p>
                  </div>
                </div>
                <div className="col-span-1 md:col-span-4 lg:col-span-6 flex justify-end hidden md:block">
                  <div className='w-[100%] lg:w-[80%] text-white'>
                    <div className="rounded-xs overflow-hidden flex">
                    <Image
                        src="/assets/images/GS.jpg"
                        width={492}
                        height={492}
                        
                      />
                      </div>
                      <p className="text-md font-bold mt-4">Gurasa Siagian</p>
                      <p className="text-xs text-white-100">President Director</p>
                  </div>
                </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-8 lg:-mt-10">

                <div className="col-span-1 md:hidden  flex flex-col text-white my-8">
                  <div className='grid grid-cols-2 rounded-xs overflow-hidden'>
                    <div className='h-[300px] overflow-hidden flex justify-center'>
                    <Image
                        src="/assets/images/GS.jpg"
                        width={492}
                        height={492}
                        style={{objectFit: "cover"}}
                        className="max-w-none h-[100%] w-auto"
                      />
                      </div>
                      <div className='h-[300px] overflow-hidden flex justify-center'>
                    <Image
                        src="/assets/images/CH.jpg"
                        width={492}
                        height={492}
                        style={{objectFit: "cover"}}
                        className="max-w-none h-[100%] w-auto"
                      />
                      </div>

                      <div className='h-[300px] overflow-hidden flex justify-center'>
                    <Image
                        src="/assets/images/HH.jpg"
                        width={492}
                        height={492}
                        style={{objectFit: "cover"}}
                        className="max-w-none h-[100%] w-auto"
                      />
                      </div>
                      
                  </div>
                  <div className="grid grid-cols-2">
                    <div>
                      <p className="text-sm font-bold mt-4">Gurasa Siagian</p>
                      <p className="text-xs text-white-100">President Director</p>
                      </div>
                      <div>
                      <p className="text-sm font-bold mt-4">Christian Hermawan</p>
                      <p className="text-xs text-white-100">Komisaris</p>
                      </div>
                  </div>
                </div>

                <div className="col-span-1 md:col-span-8 lg:col-span-6  text-white-100 flex items-end">
                  <div className='grid col-span-6 md:pl-20 text-[#D2D2CF] mb-12'>
                    <p>
                    PT Trust Sekuritas meyakini bahwa semua orang berhak mendapat akses ke investasi yang aman dan terpercaya, terlepas dari tingkat pendapatannya.
                    </p>
                  </div>
                </div>
                <div className="md:order-first col-span-1 md:col-span-4 lg:col-span-6 flex justify-start hidden md:block">
                  <div className='w-[100%] lg:w-[80%] text-white'>
                    <div className="rounded-xs overflow-hidden flex">
                    <Image
                        src="/assets/images/CH.jpg"
                        width={492}
                        height={492}
                        
                      />
                      </div>
                      <p className="text-md font-bold mt-4">Christian Hermawan</p>
                      <p className="text-xs text-white-100">Komisaris</p>
                  </div>
                </div>
                
          </div>

          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-12">
                <div className="col-span-1 md:col-span-8 lg:col-span-6  text-white-100 flex items-end">
                  <div className='grid col-span-6 md:pr-20'>
                    <p className="text-[#D2D2CF] mb-12">
                    Karena investasi berguna untuk meringankan dampak inflasi, meningkatkan kekayaan dan persiapan keuangan yang lebih baik di masa depan.
                    </p>
                  </div>
                </div>
                <div className="col-span-1 md:col-span-4 lg:col-span-6 flex justify-end hidden md:block">
                  <div className='w-[100%] lg:w-[80%] text-white'>
                    <div className="rounded-xs overflow-hidden flex">
                    <Image
                        src="/assets/images/HH.jpg"
                        width={492}
                        height={492}
                        
                      />
                      </div>
                      <p className="text-md font-bold mt-4">Herry Harto</p>
                      <p className="text-xs text-white-100">Director</p>
                  </div>
                </div>
          </div>

          {/* <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-16">
                <div className="col-span-1 md:col-span-8 lg:col-span-6  text-white-100 flex items-end">
                  <div className='grid col-span-6 md:pl-20 text-[#D2D2CF]'>
                    <p>
                    PT Trust Sekuritas meyakini bahwa semua orang berhak mendapat akses ke investasi yang aman dan terpercaya, terlepas dari tingkat pendapatannya.<br/><br/>Karena investasi berguna untuk meringankan dampak inflasi, meningkatkan kekayaan dan persiapan keuangan yang lebih baik di masa depan.
                    </p>
                  </div>
                </div>
               <div className="md:order-first col-span-1 md:col-span-4 lg:col-span-6 flex justify-start hidden md:block">
                  <div className='w-[100%] lg:w-[80%] text-white'>
                    <div className="rounded-xs overflow-hidden flex">
                    <Image
                        src="/assets/images/HH.jpg"
                        width={492}
                        height={492}
                        
                      />
                      </div>
                      <p className="text-md font-bold mt-4">Herry Harto</p>
                      <p className="text-xs text-white-100">Director</p>
                  </div>
                </div>

          </div> */}

        </div>
      </div>

      {/* <div className="container px-6 w-full md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-10/12 mx-auto md:px-4 relative gap-4 mb-20 text-center">
        <h1 className="text-black text-2xl lg:text-h1 text-black font-bold mb-6 mt-12">Mulai Trading di PINA Trade</h1>
        <p>PINA Trade di desain untuk memudahkan kamu jual-beli saham tanpa hambatan.</p>
      </div> */}

      {/* <div className="container px-6 w-full md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-10/12 mx-auto md:px-4 relative gap-4 mb-20">
        <Swiper 
          slidesPerView={1}
          spaceBetween={40}
          pagination={true}
          breakpoints={{
            
            640: {
              slidesPerView: 3,
            },
            
          }}
          modules={[Pagination]}
          className="grid grid-cols-1 md:grid-cols-3 gap-12 auto-rows-max">
          <SwiperSlide style={{ height:'auto',display:'flex',alignSelf:'stretch' }}>
            <div className="w-full bg-[#928849]-600 rounded-2xl overflow-hidden group relative flex justify-between flex-col">
              <div className="px-6 py-10">
                <p className="text-white font-semibold pb-4 text-lg">Buat RDN Instan</p>
                <p className="text-white" >RDN selesai dalam hitungan <br className="hidden md:block" />menit tanpa minimum deposit <br className="hidden md:block" />dan biaya bulanan</p>
              </div>
              <div className="relative bg-[#928849]-600 transition-all flex justify-content items-center duration-600 ease-in overflow-hidden justify-center">
                <Image
                  src="/assets/images/homepage/feature01.jpg"
                  alt="feature-register"
                  className="w-[100%] opacity-0"
                  width={400}
                  height={511}
                  priority
                />
                <Image
                  src="/assets/images/homepage/feature01.jpg"
                  alt="feature-register"
                  className="absolute h-[100%] max-w-none group-hover:w-[110%] group-hover:h-[110%] group-hover:opacity-60 w-[100%] transition-all object-center duration-200 ease-in"
                  width={400}
                  height={511}
                  priority
                />
              </div>

            </div>
          </SwiperSlide>

          <SwiperSlide style={{ height:'auto',display:'flex',alignSelf:'stretch' }}>
            <div className="w-full bg-[#928849]-600 rounded-2xl overflow-hidden group relative flex justify-between flex-col">
              <div className="px-6 py-10">
                <p className="text-white font-semibold pb-4 text-lg">Buat Workspace</p>
                <p className="text-white" >Lacak, analisa, dan buat <br className="hidden md:block" />keputusan lebih cepat dengan <br className="hidden md:block" />workspacemu sendiri</p>
              </div>
              <div className="relative bg-[#928849]-600 transition-all flex justify-content items-center duration-600 ease-in overflow-hidden justify-center">
                <Image
                  src="/assets/images/homepage/feature02.jpg"
                  alt="feature-register"
                  className="w-[100%] opacity-0"
                  width={400}
                  height={511}
                  priority
                />
                <Image
                  src="/assets/images/homepage/feature02.jpg"
                  alt="feature-register"
                  className="absolute h-[100%] max-w-none group-hover:w-[110%] group-hover:h-[110%] group-hover:opacity-60 w-[100%] transition-all object-center duration-200 ease-in"
                  width={400}
                  height={511}
                  priority
                />
              </div>

            </div>
          </SwiperSlide>

          <SwiperSlide style={{ height:'auto',display:'flex',alignSelf:'stretch' }}>
            <div className="w-full bg-[#928849]-600 rounded-2xl overflow-hidden group relative flex justify-between flex-col">
              <div className="px-6 py-10">
                <p className="text-white font-semibold pb-4 text-lg">Trading Saham</p>
                <p className="text-white" >Pengalaman trading saham <br className="hidden md:block" />terbaik cukup dengan satu <br className="hidden md:block" />platform</p>
              </div>
              <div className="relative bg-[#928849]-600 transition-all flex justify-content items-center duration-600 ease-in overflow-hidden justify-center">
                <Image
                  src="/assets/images/homepage/feature03.jpg"
                  alt="feature-register"
                  className="opacity-0 w-[100%]"
                  width={400}
                  height={511}
                  priority
                />
                <Image
                  src="/assets/images/homepage/feature03.jpg"
                  alt="feature-register"
                  className="absolute h-[100%] max-w-none group-hover:w-[110%] group-hover:h-[110%] group-hover:opacity-60 w-[100%] transition-all object-center duration-200 ease-in"
                  width={400}
                  height={511}
                  priority
                />
              </div>

            </div>
          </SwiperSlide>
        </Swiper>
        <div >
          
          
          

          


        </div>
      </div>
      
      <div className="bg-[#665800]">
        <div className="container px-6 w-full md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-10/12 mx-auto md:px-4 relative gap-4 mb-20 py-10">
          <h1 className="text-2xl lg:text-h1 text-center  text-white font-bold mb-8">Benefit menjadi member PINA Trade</h1>
          <ul className="list-disc pl-[1.5rem] text-white mb-12">
              <li className="mb-6">Akses <strong>100+ Workshop</strong> setiap Senin & Selasa (kecuali hari libur) jam 19.00-20.30</li>
              <li className="mb-6">Akses <strong>100+ Webinar</strong> setiap Rabu & Kamis (kecuali hari libur) jam 19.00-20.30</li>
              <li className="mb-6">Akses <strong>replay semua video Webinar & Workshop</strong> tanpa batas sampai cerdas</li>
              <li className="mb-6"><strong>Kesempatan berdiskusi langsung</strong> dengan Trader Saham berpengalaman saat Webinar & Workshop</li>
              <li className="mb-6">Informasi dan <strong>update Trading Signal setiap minggu</strong></li>
              <li className="mb-6"><strong>Full Access Platform Trading Saham</strong> berbasis website dengan fitur lengkap: <strong>Custom Workspace, Portfolio Manager, Auto Trading</strong> hingga <strong>Smart Oder</strong></li>
              <li className="mb-6"><strong>Full Access Tools Analisa Saham</strong> dengan fitur: <strong>Advance Chart (real-time)</strong> dan <strong>Compare Stock</strong></li>
          </ul>
          <div className="px-0 md:px-6 mb-4">
            <a href={`https://app.pinatrade.id/register${queryStr}`} className="button bg-[#928849] mt-10 px-12 py-3 rounded-lg hover:bg-[#928849] cursor-pointer transition">
              <span className="text-white text-md">Daftar Gratis</span>
            </a>
            <div className="px-8 py-8 mt-10 border-[6px] rounded-xl border-[#928849]-600 flex item-center justify-between">
              <h1 className="flex items-center text-sm md:text-lg lg:text-4xl text-white">Annual Membership Fee</h1>
              <h1 className="flex items-center text-lg md:text-xl lg:text-5xl text-white font-bold"><u>Gratis!!!</u></h1>
            </div>
          </div>
          
        </div>
      </div> */}

      {/* <div className="container px-6 w-full text-center md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-10/12 mx-auto md:px-4 relative gap-4 mb-20">
        <h1 className="text-2xl lg:text-h1 text-black font-bold mb-6 mt-12">Prioritas Keamanan PINA</h1>
        <div className="mt-20 mb-12">
          <p className="mb-4 text-black">
            <strong>Powered By</strong>
          </p>
          <div className="relative w-50 h-50 lg:h-80 lg:w-80 flex items-center m-auto">
            <Image alt="" src="/assets/images/homepage/trust.png" width={780} height={246} className="w-[300px] md:w-full" />
          </div>
        </div>




        <p className="mt-20 mb-8">
          <strong>Partner Terpercaya</strong>
        </p>
        <div className="mb-10 md:mb-0 md:w-12/12 grid grid-cols-2 md:grid-cols-5 gap-6 items-center mt-6">
          <div className="relative w-26 h-[50px] lg:h-28 lg:w-28 flex items-center m-auto">
            <Image alt="" src="/assets/images/homepage/bni.png" width={180} height={159} className="w-[90px] md:w-full" />
          </div>
          <div className="relative w-26 h-[50px] lg:h-28 lg:w-28 flex items-center m-auto">
            <Image alt="" src="/assets/images/homepage/bca.png" width={180} height={159} className="w-[90px] md:w-full" />
          </div>
          <div className="relative w-26 h-[50px] lg:h-28 lg:w-28 flex items-center m-auto">
            <Image alt="" src="/assets/images/homepage/mandiri.png" width={180} height={159} className="w-[90px] md:w-full" />
          </div>
          <div className="relative w-26 h-[50px] lg:h-28 lg:w-28 flex items-center m-auto">
            <Image alt="" src="/assets/images/homepage/cimb.png" width={180} height={159} className="w-[90px] md:w-full" />
          </div>
          <div className="relative w-26 h-[50px] lg:h-28 lg:w-28 flex items-center m-auto">
            <Image alt="" src="/assets/images/homepage/sinarmas_asset.png" width={180} height={159} className="w-[90px] md:w-full" />
          </div>
          
          
          

        </div>
        <div className="flex justify-center w-full">
          <div className="w-full md:w-9/12 grid grid-cols-2 md:grid-cols-4 gap-6 items-center">
            <div className="relative w-26 h-[50px] lg:h-28 lg:w-28 flex items-center m-auto">
              <Image alt="" src="/assets/images/homepage/bni_asset.png" width={180} height={159} className="w-[90px] md:w-full" />
            </div>
            <div className="relative w-26 h-[50px] lg:h-28 lg:w-28 flex items-center m-auto">
              <Image alt="" src="/assets/images/homepage/sucor.png" width={180} height={159} className="w-[90px] md:w-full" />
            </div>
            <div className="relative w-26 h-[50px] lg:h-28 lg:w-28 flex items-center m-auto">
              <Image alt="" src="/assets/images/homepage/avrist.png" width={180} height={159} className="w-[90px] md:w-full" />
            </div>
            <div className="relative w-26 h-[50px] lg:h-28 lg:w-28 flex items-center m-auto">
              <Image alt="" src="/assets/images/homepage/anargya.png" width={180} height={159} className="w-[90px] md:w-full" />
            </div>
          </div>
        </div>
        <div className="flex justify-center w-full">
          <div className="w-full md:w-5/12 grid grid-cols-2 gap-6 items-center mb-20">
            <div className="relative w-28 h-28 lg:h-40 lg:w-40 flex items-center m-auto">
              <Image alt="" src="/assets/images/homepage/kisi_asset.png" width={180} height={159} className="w-[90px] md:w-full" />
            </div>
            <div className="relative w-28 h-28 lg:h-28 lg:w-28 flex items-center m-auto">
              <Image alt="" src="/assets/images/homepage/ksei.png" width={130} height={100} className="w-[90px] md:w-full" />
            </div>
          </div>
        </div>
        <div>
          <p className="mb-4 text-black">
            <strong>Enkripsi Data</strong>
          </p>
          <p>Server PINA ada di lokasi aman dengan sertifikasi tier 3 ISO. Enkripsi data transaksi <br className="hidden lg:block" /> (SECTIGO RSA Domain Validation Secure Server CA) dan perlindungan koneksi dengan enkripsi 256-bit.</p>
        </div>
      </div> */}

      <div className="container px-6 w-full text-center md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-10/12 mx-auto md:px-4 relative gap-4 mb-20">
        <h1 className=" text-2xl lg:text-h1 text-black font-bold mb-6 mt-12">Frequently Asked Questions</h1>
        <div className="text-left border-b">
          <div className="w-full border-t pt-8 md:pt-12 md:px-6">
            <button className="w-full flex justify-between cursor-pointer" onClick={()=>{handleClick('tab1')}}>
              <p className="text-[#928849]-600 font-semibold text-lg text-left pr-4 lg:text-xl">Apa itu PINA Trade?</p>
              <Image alt="" src="/assets/images/homepage/arrow.svg" width={30} height={30} className={`${checkOpen("tab1") ? "" : "rotate-180"} transition-all`}/>
            </button>
            
            <div className={`relative w-full md:w-[85%] overflow-hidden transition-all ${checkOpen("tab1") ? "max-h-[300px] pt-4 mb-10" : "max-h-0 mb-8 md:mb-12"}`}>
              <span>Pina Trade adalah platform trading saham yang dilengkapi algoritma canggih dan fitur trading saham fleksible sesuai dengan kebutuhan investor. Pina trade membantu investor jadi lebih efektif dan efisien saat melakukan analisa dan transaksi melalui 1 (satu) aplikasi saham.</span>
              </div>
            
          </div>

          <div className="w-full border-t pt-8 md:pt-12 md:px-6">
            <button className="w-full flex justify-between cursor-pointer" onClick={() => { handleClick('tab2') }}>
              <p className="text-[#928849]-600 font-semibold text-lg text-left pr-4 lg:text-xl">Apa yang membuat PINA Trade berbeda dari platform trading saham lain?</p>
              <Image alt="" src="/assets/images/homepage/arrow.svg" width={30} height={30} className={`${checkOpen("tab2") ? "" : "rotate-180"} transition-all`} />
            </button>

            <div className={`relative w-full md:w-[85%] overflow-hidden transition-all ${checkOpen("tab2") ? "max-h-[300px] pt-4 mb-10" : "max-h-0 mb-8 md:mb-12"}`}>
              <span>Pina trade menawarkan fitur lengkap yang bisa menyesuaikan dengan kebutuhan investor sehingga para investor memiliki pengalaman Investasi yang menarik dan berbeda melalui 1 (satu) aplikasi saham.
                <br /><br />
                Investor bebas membuat tampilan sesuai dengan custom workspace, hingga memanfaatkan algoritma trading untuk memaksimalkan profit dengan fitur stop loss dan smart order.</span>
            </div>

          </div>

       

          <div className="w-full border-t pt-8 md:pt-12 md:px-6">
            <button className="w-full flex justify-between cursor-pointer" onClick={() => { handleClick('tab4') }}>
              <p className="text-[#928849]-600 font-semibold text-lg text-left pr-4 lg:text-xl">Apakah PINA Trade aman dan terjamin?</p>
              <Image alt="" src="/assets/images/homepage/arrow.svg" width={30} height={30} className={`${checkOpen("tab4") ? "" : "rotate-180"} transition-all`} />
            </button>

            <div className={`relative w-full md:w-[85%] overflow-hidden transition-all ${checkOpen("tab4") ? "max-h-[300px] pt-4 mb-10" : "max-h-0 mb-8 md:mb-12"}`}>
              <span>Ya, Pina Trade merupakan aplikasi Investasi milik PT Trust Sekuritas yang sudah terdafar dan diawasi oleh OJK. Dana Investasi kamu pun tersimpan aman di Rekening Dana Nasabah atas nama kamu sendiri. Dan saham kamu tersimpan aman di subrek KSEI atas nama kamu sendiri.</span>
            </div>

          </div>

        

          <div className="w-full border-t pt-8 md:pt-12 md:px-6">
            <button className="w-full flex justify-between cursor-pointer" onClick={() => { handleClick('tab6') }}>
              <p className="text-[#928849]-600 font-semibold text-lg text-left pr-4 lg:text-xl">Berapa transaction fee di PINA Trade?</p>
              <Image alt="" src="/assets/images/homepage/arrow.svg" width={30} height={30} className={`${checkOpen("tab6") ? "" : "rotate-180"} transition-all`} />
            </button>

            <div className={`relative w-full md:w-[85%] overflow-hidden transition-all ${checkOpen("tab6") ? "max-h-[300px] pt-4 mb-10" : "max-h-0 mb-8 md:mb-12"}`}>
              <span>PINA Trade menawarkan transaction fee yang kompetitif, yaitu buy fee 0,15% dan sell fee 0,25% tanpa ada biaya tambahan setiap bulan.</span>
            </div>

          </div>

          <div className="w-full border-t pt-8 md:pt-12 md:px-6">
            <button className="w-full flex justify-between cursor-pointer" onClick={() => { handleClick('tab7') }}>
              <p className="text-[#928849]-600 font-semibold text-lg text-left pr-4 lg:text-xl">Berapa lama proses pembukaan RDN di PINA Trade?</p>
              <Image alt="" src="/assets/images/homepage/arrow.svg" width={30} height={30} className={`${checkOpen("tab7") ? "" : "rotate-180"} transition-all`} />
            </button>

            <div className={`relative w-full md:w-[85%] overflow-hidden transition-all ${checkOpen("tab7") ? "max-h-[300px] pt-4 mb-10" : "max-h-0 mb-8 md:mb-12"}`}>
              <span>Pembukaan RDN di PINA Trade sangat cepat karena hanya membutuhkan waktu maksimal (1x24 jam).</span>
            </div>

          </div>


        </div>

       
      </div>

      { <div className="container px-6 w-full mx-auto md:px-4 relative gap-4  py-10">
        <div className='text-xs max-w-[600px]'>
          <p>
            <sup>*</sup>Syarat & Ketentuan Promo:
          </p>
          <ul className="list-decimal ml-4">
            <li>Kuota terbatas untuk 100 pengguna yang trading setiap bulannya, promo berlaku selama persediaan masih ada.</li>
            <li>Komisi trading 0,15% berlaku untuk trading volume hingga 50 juta rupiah. <span className='font-bold'>Trading volume lebih dari 50 juta rupiah</span> menggunakan komisi trading 0,1%.</li>
            <li>Pengguna PINA Trade yang masuk dalam kuota promo akan otomatis mendapatkan Rebate pada bulan Januari 2024. Nilai Rebate bervariasi tergantung pada trading volume selama periode promo.</li>
          </ul>
        </div>
      </div> }
      <div className="container px-6 w-full mx-auto md:px-4 relative gap-4 py-10">
        <div className='text-xs '>
        <Image alt="" src="/assets/images/homepage/tradingviewgbr.jpeg" width={330} height={100} className="max-w-[250px]" />

      <p>
      Chart yang kami gunakan disediakan oleh TradingView, sebuah platform charting bagi para trader dan investor dari seluruh penjuru dunia. Temukan berbagai instrumen finansial seperti chart EURUSD, BTCUSDT, IHSG, dan juga peralatan seperti <a href='https://www.tradingview.com/screener/' className='text-[#928849]'  rel="noreferrer" target='_blank'>Stock Screener</a> yang tersedia secara gratis dan dapat membantu dalam aktivitas trading dan investasi anda.      </p>
      </div>
      </div>
    </main>
  )

}

Homepage.getLayout = function getLayout(page) {
  return (
    <DefaultLayout home={true}>
      {page}
    </DefaultLayout>
  )
}

export default Homepage
