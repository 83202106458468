import PropTypes from 'prop-types';

const IconChevronRight = ({ className }) => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.663509 9.33435C0.445497 9.11348 0.445497 8.75538 0.663509 8.53451L4.16479 4.98731L0.893705 1.4524C0.682836 1.22452 0.694232 0.866603 0.919159 0.65297C1.14409 0.439334 1.49737 0.450881 1.70824 0.678757L5.34901 4.61318C5.55509 4.83588 5.54955 5.18407 5.33649 5.39992L1.453 9.33435C1.23499 9.55522 0.88152 9.55522 0.663509 9.33435Z"
        fill="#292929"
      />
    </svg>
  );
};

IconChevronRight.propTypes = {
  className: PropTypes.string,
};

IconChevronRight.defaultProps = {
  className: '',
};

export default IconChevronRight;
